<template>
    <div class="menusSidebar d-flex flex-column" data-sticky-container>
        <slot></slot>

        <div class="pageIntro d-block d-md-none">
            <div class="container">
                <div class="pageIntro__content" v-html="intro"></div>
            </div>
        </div>

        <div class="d-block d-md-none">
            <RestaurantMenusNav :menus="menus" :active="active"></RestaurantMenusNav>
        </div>

        <div class="menusLogo mt-6 mb-8 mt-md-4 mb-md-5">
            <template v-for="restaurant in menus">
                <img v-if="restaurant.id == active" :src="restaurant.image" :alt="restaurant.displayName" class="mx-auto" />
            </template>
        </div>

        <div class="menusNav pb-0 pb-md-12" data-margin-top="32">
            <div class="sectionNav py-3 px-3 position-relative bg-light">
                <div class="sectionNav__title text-uppercase bg-primary position-absolute text-white">Menus</div>

                <div v-if="loading" class="sectionNav__loading text-center mt-7 mb-5">
                    <div class="spinner-border opacity-25" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div v-else>
                    <ul v-if="menu" class="list-unstyled text-center mt-2 fw-bold text-uppercase my-0">
                        <li v-for="section in menu.sections">
                            <a :href="'#' + section.slug">{{ section.name }}</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="menusSidebar__download my-4">
                <a v-if="menuDownloadUrl" :href="menuDownloadUrl" class="button button-primary d-flex align-items-center justify-content-center" target="_blank" rel="noopener">
                    <span>Download Menu</span>
                    <inline-svg src="/app/themes/delivery/images/icons/download.svg" class="ms-1"></inline-svg>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import Gumshoe from 'gumshoejs';
import InlineSvg from 'vue-inline-svg';
import RestaurantMenusNav from './RestaurantMenusNav.vue';

export default {
    components: { RestaurantMenusNav, InlineSvg },

    props: {
        locationName: {
            type: String,
            default: '',
        },
        menus: {
            type: Array,
            required: true,
        },
        active: {
            type: String,
            default: null,
        },
        intro: {
            type: String,
            required: true,
        },

        /**
         * The currently targeted menu data.
         */
        menu: {
            type: Object,
            default: null,
        },

        /**
         * Whether data is loading in the parent component.
         */
        loading: {
            type: Boolean,
            required: true,
        },

        /**
         * The menu pdf download url.
         */
        menuDownloadUrl: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            sections: [],
            spy: null,
        };
    },

    computed: {
        affixEnabled() {
            const deviceWidth = window.innerWidth > 0 ? window.innerWidth : screen.width;
            return deviceWidth > 768;
        },
    },

    watch: {
        loading() {
            this.updateScrollSpy();
        },
    },

    methods: {
        updateScrollSpy() {
            this.$nextTick(() => {
                this.spy = new Gumshoe('.sectionNav a', {
                    reflow: true,
                    navClass: 'isActive',
                    contentClass: 'isActive',
                    offset: 56,
                });
            });
        },
    },
};
</script>
