<template>
    <div class="faqs mt-6 mt-md-10">
        <div class="container">
            <div class="row gx-12 justify-content-center justify-content-md-between">
                <div class="faqs__nav col-12 col-md-3 mb-4 mb-md-0" data-sticky-container>
                    <div class="faqsAffix" data-margin-top="16">
                        <img :src="badge" alt="Pappas Delivery" />

                        <ul class="faqs__nav__items list-unstyled pt-4 pb-3 px-3 text-center">
                            <li v-for="cat in items" class="faq__navItem">
                                <a :href="'#' + cat.slug">{{ cat.title }}</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="faqs__items col-12 col-md-9">
                    <div class="faqsIntro text-center text-uppercase" v-html="intro"></div>
                    <FaqsPanel v-for="cat in items" :key="cat.slug" :cat="cat"></FaqsPanel>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Gumshoe from 'gumshoejs';
import FaqsPanel from './FaqsPanel.vue';
import StickyJs from 'sticky-js';

export default {
    components: { FaqsPanel },

    props: {
        intro: {
            type: String,
            required: true,
        },
        badge: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
    },

    computed: {
        affixEnabled() {
            const deviceWidth = window.innerWidth > 0 ? window.innerWidth : screen.width;
            return deviceWidth > 768;
        },
    },

    mounted() {
        const spy = new Gumshoe('.faq__navItem a', {
            reflow: true,
            navClass: 'faq__navItem--active',
            contentClass: 'isActive',
            offset: 56,
        });

        new StickyJs('.faqsAffix');
    },

    methods: {
        activate(slug) {
            this.active = slug;
        },
    },
};
</script>
