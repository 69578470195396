<template>
  <div :class="switcherRootClass" ref="switcherRoot">
    <div class="switcher__inner">
      <div class="switcher__current text-uppercase" @click.prevent="toggle">
        <div class="switcher__current__label">
          <span>{{ context }}</span>
          <strong class="text-decoration-underline">{{ currentLocation.title }}</strong>
        </div>
        <div class="switcher__current__arrow">
          <img :src="'/app/themes/delivery/images/arrow.png'" alt="Downward-pointing arrow">
        </div>
      </div>
    </div>
    <div class="switcher__menu" ref="switcherMenu">
      <div class="switcher__menu__inner">
        <span>Change location to:</span>
        <template v-for="location in locations">
          <a v-if="!isActive(location)" href class="button button--slim button--primary" @click.prevent="updateLocation(location)" :key="location.id">
            {{ location.title }}
          </a>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import Cookies from 'js-cookie';

  export default {
    props: {
      locations: Array,
      currentLocationId: Number,
      cookieName: String,
      cookieDomain: String,
      context: {
        type: String,
        default: 'Delivery in',
      }
    },

    data() {
      return {
        open: false
      }
    },

    computed: {
      /**
       * The currently selected location
       *
       * @return  {Object}
       */
      currentLocation() {
        return this.locations.find(location => location.id == this.currentLocationId);
      },

      /**
       * Gets the domain without the port
       *
       * This exists because BrowserSync appends its port to all instances of
       * the URL in the document and does not provide a readily available
       * configuration option to prevent that.
       *
       * @return  {String}
       */
      sanitizedCookieDomain() {
        return this.cookieDomain.replace(/:[0-9]+/, '');
      },

      isMobile() {
        return this.$refs.switcherRoot.classList.contains('switcher--mobile');
      },

      switcherRootClass() {
        let classList = ['switcher'];

        if(this.open) {
          classList.push('switcher--open');
        }

        return classList;
      }
    },

    watch: {
      open(value) {
        if(value) {
          this.$nextTick(() => {
            console.log('scrolling');

            const offset = this.$refs.switcherRoot.offsetTop;

            this.$refs.switcherMenu.scrollIntoView(false);

            // console.log('offset', offset);

            document.querySelector('.mobileNav').scrollTop = document.querySelector('.mobileNav').scrollHeight;

            // console.log(document.querySelector('.mobileNav').scrollTop);
          });
        }
      }
    },

    methods: {

      /**
       * Determines whether a passed location is currently active.
       *
       * @param   {Object}   location  The location
       * @return  {boolean}  True if the specified location is active, False otherwise.
       */
      isActive(location) {
        return location == this.currentLocation;
      },

      /**
       * Generate a button's classlist
       *
       * @param   {Object}  location  The location
       * @return  {Array}   CSS classes to apply to the target button
       */
      buttonClass(location) {
        const classList = ['button', 'button--slim'];

        if(this.isActive(location)) {
          classList.push('button--white');
        } else {
          classList.push('button--primary');
        }

        return classList;
      },

      /**
       * Update the currently active location.
       *
       * @param  {Object}  location  The location
       */
      updateLocation(location) {
        if(this.isActive(location)) {
          return;
        }

        Cookies.remove(this.cookieName);
        Cookies.set(this.cookieName, location.id, {
          domain: this.sanitizedCookieDomain,
          expires: 1,
        });

        window.location.reload();
      },

      /**
       * Manually toggle the switcher's open state
       * This is used for mobile views
       */
      toggle() {
        if(!this.isMobile) {
          return;
        }

        this.open = !this.open;
        // console.log(this.$refs.switcherRoot, this.isMobile);
      }
    }
  }
</script>



