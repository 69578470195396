<template>
    <section class="restaurantMenus">
        <a name="top"></a>
        <div class="container">
            <div class="row gx-0 justify-content-between flex-md-nowrap" id="menu-viewport">
                <RestaurantMenusSidebar :menu="menu" :menus="menus" :location-name="locationName" :menu-download-url="menuDownloadUrl" :active="active" :intro="intro" :loading="loading">
                    <LocationSwitcher
                        :locations="locations"
                        :current-location-id="currentLocationId"
                        :cookie-name="cookieName"
                        :cookie-domain="cookieDomain"
                        context="Delivery Location"
                        class="switcher--stacked switcher--no-badge"
                    ></LocationSwitcher>
                </RestaurantMenusSidebar>
                <RestaurantMenusContent :menu="menu" :menus="menus" :intro="intro" :active="active" :order-url="orderUrl" :loading="loading">
                    <template v-slot:banner>
                        <slot name="banner"></slot>
                    </template>
                </RestaurantMenusContent>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import LocationSwitcher from './LocationSwitcher.vue';
import RestaurantMenusSidebar from './RestaurantMenusSidebar.vue';
import RestaurantMenusContent from './RestaurantMenusContent.vue';
import StickyJs from 'sticky-js';

export default {
    components: {
        LocationSwitcher,
        RestaurantMenusSidebar,
        RestaurantMenusContent,
    },

    props: {
        intro: {
            type: String,
            required: true,
        },
        locationName: {
            type: String,
            default: '',
        },
        menus: {
            type: Array,
            required: true,
        },
        orderUrl: {
            type: String,
            default: '#',
        },

        /**
         * The menu pdf download url.
         */
        menuDownloadUrl: {
            type: String,
            default: null,
        },

        /** passed to location switcher */
        locations: Array,
        currentLocationId: Number,
        cookieName: String,
        cookieDomain: String,
    },

    data() {
        return {
            active: this.menus[0].id,
            menu: null,
            loading: true,
        };
    },

    computed: {
        ids() {
            return this.menus.map((menu) => menu.id);
        },
        activeMarqiiId() {
            if (!this.active) {
                return this.active;
            }

            return this.menus.find((menu) => menu.id == this.active).marqii_id;
        },
    },

    watch: {
        active() {
            this.fetchMenu();
        },
    },

    created() {
        window.addEventListener('hashchange', () => this.activateFromHash());
        this.activateFromHash();
        this.fetchMenu();
    },

    mounted() {
        new StickyJs('.menusNav');
    },

    methods: {
        /**
         * Retrieve the active menu from the WP REST api.
         *
         * @returns {Object}
         */
        fetchMenu() {
            this.loading = true;

            axios
                .get('/wp-json/pappas/v1/menus/' + this.activeMarqiiId)
                .then(({ data }) => (this.menu = data))
                .catch((err) => console.error(err))
                .then(() => (this.loading = false));
        },

        activateFromHash() {
            if (!window.location.hash) {
                return;
            }

            const id = window.location.hash.replace('#', '');

            if (this.ids.includes(id)) {
                this.active = id;
            }
        },
    },
};
</script>
