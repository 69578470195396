<template>
  <div class="menusContent col">
    <div class="pageIntro d-none d-md-block">
      <div class="container">
        <div class="pageIntro__content" v-html="intro"></div>
      </div>
    </div>

    <div class="menusContent__nav d-none d-md-block">
      <div class="row justify-content-center align-items-stretch gx-0 flex-wrap flex-md-nowrap">
        <div
          v-for="restaurant in menus"
          class="col-3 d-flex align-items-center justify-content-center"
          :key="'menusContentNavItem--' + restaurant.id"
        >
          <a
            :href="'#' + restaurant.id"
            :class="{ isActive: restaurant.id == active }"
          >
            <img :src="restaurant.image" :alt="restaurant.name" />
          </a>
        </div>
      </div>
    </div>

    <div v-if="loading" class="menusContent__loading text-center py-12">
      <div class="spinner-border opacity-50 text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="opacity-75 mt-2">Loading Menu</div>
    </div>
    <div v-else>
      <slot name="banner"></slot>

      <div
        class="menusContent__menu"
      >
        <div
          v-for="(section, index) in menu.sections"
          class="menuSection"
          :id="section.slug"
        >
          <div class="menuSection__header mb-3">
            <h4>{{ section.name }}</h4>
            <div
              v-if="section.description"
              v-html="nl2br(section.description)"
            ></div>
          </div>

          <div class="menuItems row gx-3">
            <div
              v-for="(item, index) in section.items"
              class="menuItem col-12 col-md-6 mb-4"
            >
              <div class="menuItem__title" v-html="item.name"></div>
              <div
                class="menuItem__description"
                v-if="item.description"
                v-html="nl2br(item.description)"
              ></div>
              <ul v-if="item.options" class="menuItem__options list-unstyled">
                <li v-for="option in item.options">
                  <span>{{ option.name }}</span>
                  <span>{{ option.price }}</span>
                </li>
              </ul>
              <div v-if="item.price" class="menuItem__price">
                {{ item.price }}
              </div>
            </div>
          </div>

          <div v-if="showBanner(index)" class="menuSection__banner">
            <div
              class="row justify-content-end align-items-center gx-2 py-2 px-3 text-uppercase"
            >
              <div class="col flex-grow-0 text-nowrap">
                Easily Order Online Now
              </div>
              <div class="col flex-grow-0 text-nowrap">
                <a :href="orderUrl" class="order-online-button">Order Online</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center pt-9 pb-12">
        <a href="#top" class="button">Back to Top</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    intro: {
      type: String,
      required: true,
    },
    menus: {
      type: Array,
      default: () => [],
    },
    active: {
      type: String,
      default: null,
    },
    orderUrl: {
      type: String,
      default: "#",
    },

    /**
     * The currently targeted menu data.
     */
      menu: {
      type: Object,
      default: null,
    },

    /**
     * Whether the parent component is currently
     * loading data.
     */
    loading: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    showBanner(index) {
      return index % 2;
    },

    /**
     * Don't really want to talk about why this is here. -CE
     *
     * @param      {string}   str       The string
     * @param      {boolean}  is_xhtml  Indicates if xhtml
     * @return     {string}   { description_of_the_return_value }
     */
    nl2br(str, is_xhtml) {
      if (typeof str === "undefined" || str === null) {
        return "";
      }

      var breakTag =
        is_xhtml || typeof is_xhtml === "undefined" ? "<br />" : "<br>";

      return (str + "").replace(
        /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
        "$1" + breakTag + "$2"
      );
    },
  },
};
</script>
