import GLightbox from 'glightbox';

document.addEventListener('DOMContentLoaded', () => {
  if(!document.querySelector('.galleryGrid')) {
    return;
  }

  const lightbox = GLightbox({
    selector: '.galleryGrid__item__link',
    loop: true,
  });
})
