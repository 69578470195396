<template>
  <div class="faq" :class="classList">
    <div class="faq__question row gx-3" @click.prevent="$parent.toggleItem(item.id)">
      <div class="col-1"><b></b></div>
      <div class="col-11"><h5>{{ item.question }}</h5></div>
    </div>
    <div class="faq__answer" :style="styleAttr" ref="answer">
      <div class="row gx-3" ref="answerInner">
        <div class="col-10 offset-1" v-html="item.answerFiltered"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    /**
     * Whether the current panel is active
     */
    active: {
      type: Boolean,
      required: true,
    },

    /**
     * FAQ data
     */
    item: {
      type: Object,
      required: true
    },
  },

  data() {
    return {
    }
  },

  computed: {
    /**
     * Wrapper class list
     *
     * @return {Array}
     */
    classList() {
      const classes = ['faq'];

      if(this.active) {
        classes.push('faq--active');
      }

      return classes;
    },

    maxHeight() {
      return this.$refs.answerInner ? window.getComputedStyle(this.$refs.answerInner, null).getPropertyValue('height') : null;
    },

    styleAttr() {
      let maxHeight = 0;

      if(this.active) {
        maxHeight = this.maxHeight;
      }

      return {
        maxHeight: maxHeight,
      }
    },

  },
}
</script>
