document.addEventListener('DOMContentLoaded', function () {
    // track all of the "Order online" buttons on the site
    const orderOnlineButtons = document.querySelectorAll('.order-online-button');
    const viewMenusButtons = document.querySelectorAll('.view-menus-button');

    // in dev GA isn't set up so we'll 
    // just return if it's not there
    if (!window.gtag) return;

    // track all of the "View Menus" buttons on the site
    viewMenusButtons.forEach((anchor) => {
        anchor.addEventListener('click', (event) => {
            let label = 'View Menus Link';
            
            if (anchor.classList.contains('track-footer-cta')) {
                label += ' - Footer CTA';
            }

            gtag('event', 'click_view_menus', {
                'event_category': 'View Menus',
                'event_action': anchor.href,
                'event_label': label,
                'value': 1
            });

                
            console.log('click tracked');

            return;
        });
    });
    
    orderOnlineButtons.forEach((anchor) => {
        anchor.addEventListener('click', (event) => {
            let label = 'Order Online Link';

            // Another class prefixed with "track-" will be added to the anchor
            // if it needs to be labeled individually. This is to avoid 
            // having to add a bunch of event listeners to the DOM.
            if (anchor.classList.contains('track-mobile-nav')) {
                label += ' - Mobile Nav';
            } else if (anchor.classList.contains('track-desktop-nav')) {
                label += ' - Desktop Nav';
            } else if (anchor.classList.contains('track-footer-cta')) {
                label += ' - Footer CTA';
            } else if (anchor.classList.contains('track-menu-cta')) {
                label += ' - Menu CTA';
            }

            // anchor.classList.contains('navigation-button')

            gtag('event', 'click_order_online', {
                'event_category': 'Order Online',
                'event_action': anchor.href,
                'event_label': label,
                'value': 1
            });
            
            console.log('click tracked');

            return;

            // console.log('Event sent to GA.');
        });
    });
});