import Glide from '@glidejs/glide';

document.addEventListener('DOMContentLoaded', () => {
  if(!document.querySelector('.homeMasthead')) {
    return;
  }

  const glideOpts = { autoplay: 4000 };
  const carousel = new Glide('.homeMasthead', glideOpts).mount();
});
