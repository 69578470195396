import Glide from '@glidejs/glide';

document.addEventListener('DOMContentLoaded', () => {
  if(!document.querySelector('.servicesCarousel')) {
    return;
  }

  const glideOpts = {
    perView: 3,
    peek: 96,
    gap: 20,
    bound: true,

    breakpoints: {
      768: {
        perView: 1,
        peek: 36,
      }
    }
  };

  const carousel = new Glide('.servicesCarousel', glideOpts).mount();

  carousel.on('run', (move) => {

    const inactiveClass = 'servicesCarousel__nav--inactive';

    const $prev = document.querySelector('.servicesCarousel__nav--prev');
    const $next = document.querySelector('.servicesCarousel__nav--next');

    const totalSlides = document.querySelectorAll('.service').length;
    const lastSlideIndex = totalSlides - carousel.settings.perView;

    if(carousel.index === 0) {
      $prev.classList.add(inactiveClass);
    } else {
      $prev.classList.remove(inactiveClass);
    }

    if(carousel.index === lastSlideIndex) {
      $next.classList.add(inactiveClass);
    } else {
      $next.classList.remove(inactiveClass);
    }
  });
});
