<template>
  <div class="menusContent__nav">
    <div class="row justify-content-center align-items-stretch gx-0 flex-wrap flex-md-nowrap">
      <div v-for="restaurant in menus" class="col-3 d-flex align-items-center justify-content-center" :key="'menusContentNavItem--' + restaurant.id">
        <a :href="'#' + restaurant.id" :class="{isActive: restaurant.id == active}">
          <img :src="restaurant.image" :alt="restaurant.name">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      active: {
        type: String,
        default: null,
      },
      menus: {
        type: Array,
        required: true
      },
    }
  }
</script>
