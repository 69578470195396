<template>
  <section :class="classList" :id="cat.slug">
    <div class="faqs__panel__inner">
      <div class="h5 faqs__panel__title">{{ cat.title }}</div>
      <div class="faqs__accordion">
        <FaqItem v-for="(faq, index) in faqs"
          :active="active == faq.id"
          :item="faq"
          :key="uuid()"
        ></FaqItem>
      </div>
    </div>
  </section>
</template>

<script>

import FaqItem from './FaqItem.vue';

export default {
  components: { FaqItem },

  props: {
    /**
     * FAQs category data
     */
    cat: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      /**
       * Items for use in render
       */
      faqs: this.prepare(),

      /**
       * Active accordion item id
       */
      active: false,
    }
  },

  computed: {
    /**
     * Wrapper class list
     *
     * @return {Array}
     */
    classList() {
      const classes = ['faqs__panel'];

      if(this.active) {
        classes.push('faqs__panel--active');
      }

      return classes;
    },
  },

  methods: {

    prepare() {
      let items = this.cat.faqs;

      return items.map((item) => {
        item.id = this.uuid();
        return item;
      });
    },

    toggleItem(id) {

      if(this.active == id) {
        this.active = null;
      } else {
        this.active = id;
      }
    },

    uuid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    }
  }
}
</script>
