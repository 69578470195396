<template>
  <div class="mobileNav" :class="{'mobileNav--open': open}">
    <div class="mobileNav__inner" v-if="enabled">
      <ul class="mobileNav__items list-unstyled" ref="items">
        <li v-for="item in items" :class="item.classList">
          <a :href="item.url">{{ item.label }}</a>
        </li>
        <li class="menu-item menu-item--order">
          <a :href="orderLink" target="_blank" rel="noopener" class="order-online-button track-mobile-nav">Order Online</a>
        </li>
      </ul>

      <LocationSwitcher
        class="switcher--mobile"
        :locations="locations"
        :current-location-id="currentLocationId"
        :cookie-name="cookieName"
        :cookie-domain="cookieDomain"
      ></LocationSwitcher>
    </div>
  </div>
</template>

<script>
  import LocationSwitcher from './LocationSwitcher.vue';

  export default {
    components: { LocationSwitcher },

    props: {
      orderLink: {
        type: String,
        default: ''
      },

      open: {
        type: Boolean,
        default: false,
      },

      /** passed to location switcher */
      locations: Array,
      currentLocationId: Number,
      cookieName: String,
      cookieDomain: String,
    },

    data() {
      return {
        deviceWidth: 0,
        threshold: 856,
        loaded: false,
        items: [],
      }
    },

    computed: {

      enabled() {
        return this.loaded && this.deviceWidth > 0 && this.deviceWidth <= this.threshold;
      }

    },

    watch: {
      enabled(state) {
        (state) ? this.enable() : this.disable();
      },

      open(open) {
        const $burger = document.querySelector('.siteHeader .hamburger');

        if(open) {
          $burger.classList.add('is-active');
          document.querySelector('body').classList.add('no-scroll');
        } else {
          $burger.classList.remove('is-active');
          document.querySelector('body').classList.remove('no-scroll');
        }
      }
    },

    mounted() {
      this.loaded = true;
      this.updateDeviceWidth();
      window.onresize = () => this.updateDeviceWidth();
    },

    methods: {
      enable() {

        if(!this.loaded) {
          return;
        }

        this.$nextTick(() => {
          const $navItems = document.querySelectorAll('.siteHeader .siteNav__items li');
          const items = [];

          $navItems.forEach($item => {
            const $link = $item.querySelector('a');

            items.push({
              classList: $item.classList,
              url: $link.getAttribute('href'),
              label: $link.innerHTML,
            });
          });

          this.items = items;
        });

      },

      disable() {
      },

      updateDeviceWidth() {
        this.deviceWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;
      }
    }

  }
</script>
