import '../styles/app.scss';

import './app/modules/gallery';
import './app/modules/masthead-carousel';
import './app/modules/services-carousel';
import './app/modules/ga-event-tracking';

import { createApp } from 'vue';

// import PappasLeadsForm from '@pappas/leads-form/src/components/Form.vue';
import PappasLeadsForm from '@pappas/leads-form';
// import PappasEclub from '../../../pappas-eclub/src/plugin';
import PappasEclub from '@pappas/eclub';

import Faqs from './app/components/Faqs.vue';
import RestaurantMenus from './app/components/RestaurantMenus.vue';
import LocationSwitcher from './app/components/LocationSwitcher.vue';
import MobileNav from './app/components/MobileNav.vue';

const app = createApp({
    data() {
        return { navOpen: false };
    },
});

app.component('faqs', Faqs);
app.component('restaurant-menus', RestaurantMenus);
app.component('location-switcher', LocationSwitcher);
app.component('mobile-nav', MobileNav);

app.use(PappasLeadsForm);
app.use(PappasEclub);

app.mount('#app');
